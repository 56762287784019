import { useState, useEffect, useRef, ReactNode } from "react"

import { Transition } from "react-transition-group"
import { gsap } from "gsap"

import style from "./GalleryAnim.module.scss"

type Props = {
  from: ReactNode
  to: ReactNode
  onStart?: Function
  onComplete?: Function
}

const duration = 1000
const delay = 250

export function GalleryAnim(props: Props) {
  const itemRef = useRef<HTMLDivElement>(null)

  const [inProp, setInProp] = useState<boolean>(false)

  const animEnter = () => {
    let rotateOptName = "rotateX"
    if (window.innerHeight > window.innerWidth) {
      rotateOptName = "rotateY"
    }

    gsap.to(itemRef.current, {
      //autoAlpha: 0,
      //transformOrigin: "50% 50%",
      [rotateOptName]: -180 * 1 + "deg",
      duration: duration / 1000,
      ease: "back.out",
      onStart: () => {
        if (typeof props.onStart === "function") {
          props.onStart()
        }
      },
      onUpdate: function () {
        const currRot = gsap.getProperty(
          itemRef.current,
          rotateOptName,
        ) as number
        if (currRot <= -90 && currRot >= -180) {
          itemRef.current!.classList.add(style.showTo)
        }
      },
      onComplete: () => {
        if (typeof props.onComplete === "function") {
          props.onComplete()
        }
      },
    })
    /*gsap.from(backLayRef.current, {
      opacity: 0,
      duration: duration / 1000,
      ease: "power3.out",
    })*/
  }

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <div className={[style.perspective, style.root].join(" ")}>
      <Transition
        className={[style.item].join(" ")}
        in={inProp}
        //key={"gal-item-" + props.artwork!.slug}
        nodeRef={itemRef}
        onEntering={animEnter}
        //onExit={animExit}
        timeout={duration + delay}
      >
        <div ref={itemRef}>
          <div className={style.itemFrom}>{props.from}</div>
          <div className={style.itemTo}>{props.to}</div>
        </div>
      </Transition>
    </div>
  )
}
