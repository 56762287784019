import { useState, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"

import { getPixelColor, rgbToHex, loadImage } from "../../utils/imageUtils"
import { Artwork, selectArtworks } from "../artworks/artworksSlice"
import {
  loadingStart,
  loadingEnd,
  //selectIsLoading,
} from "../loader/loaderSlice"
import { animationStart, animationEnd } from "../gallery/gallerySlice"

import { getViewportScreenshot } from "../../utils/imageUtils"

import { GalleryAnim } from "../../components/galleryAnim/GalleryAnim"
import { CardBig } from "../../components/cardBig/CardBig"

//import style from "./GalleryAnim.module.scss"

type Props = {
  onComplete?: Function
}

type Item = {
  src: string
  bgColor: string | null
}

type StateSrc = {
  from: Item | null
  to: Item | null
}

export function GalleryAnimator(props: Props) {
  //const itemRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const artworksData = useAppSelector(selectArtworks)
  const { slug } = (useParams() as any) || {}

  //const loading = useAppSelector(selectIsLoading)

  const prevRef = useRef<Item | null>(null)

  const [srcData, setSrc] = useState<StateSrc>({
    from: null,
    to: null,
  })

  //const [srcFrom, setSrcFrom] = useState<Item | null>(null)
  //const [srcTo, setSrcTo] = useState<Item | null>(null)

  const getBgColor = async (img: HTMLImageElement) => {
    const imageBitmap = await createImageBitmap(img)
    const pixel = await getPixelColor(imageBitmap, 3, 3)

    return rgbToHex(pixel[0], pixel[1], pixel[2])
  }

  const getItemComponent = (item: Item) => {
    if (item.bgColor) {
      return <CardBig src={item.src} bgColor={item.bgColor} />
    } else {
      return <img style={{ width: "100%" }} src={item.src} alt="" />
    }
  }

  useEffect(() => {
    if (slug) {
      const artW: Artwork = artworksData.find(
        (aw) => aw.slug === slug,
      ) as Artwork
      if (artW) {
        dispatch(loadingStart())
        loadImage(artW.asset.large, (data: HTMLImageElement) => {
          getBgColor(data).then((bgColor: string) => {
            if (prevRef.current) {
              dispatch(loadingEnd())
              setSrc({ from: prevRef.current, to: { src: data.src, bgColor } })
            } else {
              getViewportScreenshot().then((screenData) => {
                dispatch(loadingEnd())
                setSrc({
                  from: { src: screenData, bgColor: null },
                  to: { src: data.src, bgColor },
                })
              })
            }
            prevRef.current = { src: data.src, bgColor }
          })
        })
      }
    } else if (prevRef.current) {
      dispatch(loadingStart())
      getViewportScreenshot().then((screenData) => {
        dispatch(loadingEnd())
        setSrc({
          from: prevRef.current,
          to: { src: screenData, bgColor: null },
        })
        prevRef.current = { src: screenData, bgColor: null }
      })
    } /*else {
      prevRef.current = null
    }*/

    return () => {
      dispatch(loadingEnd())
    }
  }, [slug, artworksData, dispatch])

  return (
    <>
      {
        /*!loading &&*/ srcData.to && srcData.from && (
          <GalleryAnim
            from={getItemComponent(srcData.from)}
            to={getItemComponent(srcData.to)}
            onStart={() => dispatch(animationStart())}
            onComplete={() => {
              dispatch(animationEnd())
              setSrc({ from: null, to: null })
            }}
          />
        )
      }
    </>
  )
}
