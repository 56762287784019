import classNames from "classnames"

import { useAppSelector } from "../../app/hooks"

import { selectIsLoading } from "./loaderSlice"

import style from "./Loader.module.scss"
import { LoadSpinner } from "../../components/loadSpinner/LoadSpinner"

export const Loader = () => {
  const loading = useAppSelector(selectIsLoading)

  return (
    loading && (
      <div
        className={classNames(
          style.root,
          "d-flex",
          "justify-content-center",
          "align-items-center",
        )}
      >
        <div>
          <LoadSpinner />
          <span className="visually-hidden">loading...</span>
        </div>
      </div>
    )
  )
}
