import classNames from "classnames"

import style from "./BtnRound.module.scss"

type Props = {
  children?: any
  className?: string
  onClick?: Function
}

export function BtnRound(props: Props) {
  const handleClick = () => {
    if (typeof props.onClick === "function") {
      props.onClick()
    }
  }

  return (
    <button
      className={classNames(style.root, props.className)}
      onClick={() => handleClick()}
    >
      {props.children}
    </button>
  )
}
