import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { useParams, Link } from "react-router-dom"
import { loadData, selectArtworks } from "../features/artworks/artworksSlice"

import { MasonryList } from "../components/masonryList/MasonryList"
import { Card } from "../components/card/Card"
import { GalleryAnimator } from "../features/galleryAnimator/GalleryAnimator"
import { ArtworkMgr } from "../features/artworkMgr/ArtworkMgr"
import { GalleryNav } from "../features/galleryNav/GalleryNav"
import { Loader } from "../features/loader/Loader"
import { Profile } from "../features/profile/Profile"

import style from "./root.module.scss"

export default function Root() {
  const dispatch = useAppDispatch()
  const artworksData = useAppSelector(selectArtworks)

  useEffect(() => {
    dispatch(loadData("/tees.json"))
  }, [dispatch])

  return (
    <>
      <div id="tees-container" className={style.teesWrapper}>
        <div className={style.teesContainer}>
          {artworksData && artworksData.length > 0 && (
            <MasonryList>
              {artworksData.map((artworkData, idx) => (
                <Link key={"teeItem" + idx} to={"/artwork/" + artworkData.slug}>
                  <Card src={artworkData.asset.thumb} />
                </Link>
              ))}
            </MasonryList>
          )}
        </div>
      </div>
      <ArtworkMgr />
      <GalleryNav />
      <GalleryAnimator />
      <Profile />
      <Loader />
    </>
  )
}
