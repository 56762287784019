import React, { useEffect, useRef } from "react"
import Masonry from "masonry-layout"

//import getRandomInterval from "../../utils/getRandomInterval"

import style from "./Masonry.module.scss"

declare global {
  interface Window {
    Masonry: any
  }
}

type Props = {
  children?:
    | React.ReactElement<
        React.ReactNode,
        string | React.JSXElementConstructor<any>
      >
    | readonly React.ReactElement<
        React.ReactNode,
        string | React.JSXElementConstructor<any>
      >[]
}

export function MasonryList(props: Props) {
  const mainRef = useRef(null)
  let msnryRef = useRef<any>(null)
  const cssHeights = [
    "",
    style.hx2,
    style.hx3,
    style.hx4,
    style.hx5,
    style.hx6,
    style.hx7,
    style.hx8,
    style.hx9,
    style.hx10,
    style.hx11,
    style.hx12,
  ]

  const getHeightCss = (idx: number) => {
    //return cssHeights[getRandomInterval(0, cssHeights.length - 1)]
    return idx % 2 === 0 ? style.hx3 : style.hx7
  }

  useEffect(() => {
    msnryRef.current = new Masonry(mainRef.current!, {
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: "." + style.gridItem,
      // use element for option
      columnWidth: "." + style.gridSizer,
      gutter: "." + style.gutterSizer,
      percentPosition: true,
    })
  })

  return (
    <div id="masonry-list" className={style.wrapper}>
      <div ref={mainRef} className={style.grid}>
        <div className={style.gridSizer}></div>
        <div className={style.gutterSizer}></div>

        {props.children &&
          React.Children.map(
            props.children,
            (child: React.ReactElement<React.ReactNode>, idx) => (
              <div key={"mitem" + idx} className={style.gridItem}>
                <div className={[style.tile, getHeightCss(idx)].join(" ")}>
                  <div className={style.container}>{child}</div>
                </div>
              </div>
            ),
          )}
      </div>
    </div>
  )
}
