import { useState, useEffect } from "react"

import { getPixelColor, rgbToHex, loadImage } from "../../utils/imageUtils"
import { useAppSelector } from "../../app/hooks"
import { Artwork, selectArtworks } from "../artworks/artworksSlice"
import { useParams } from "react-router-dom"

import { selectAnimStarted, selectAnimEnded } from "../gallery/gallerySlice"

import { CardBig } from "../../components/cardBig/CardBig"

import style from "./ArtworkMgr.module.scss"

type Props = {
  onComplete?: Function
}

type StateSrc = {
  src: string | null
  bgColor: string | null
  status: "opening" | "sliding" | "closing"
}

const toDuration: number = 1000

export function ArtworkMgr(props: Props) {
  const artworksData = useAppSelector(selectArtworks)
  const animStarted = useAppSelector(selectAnimStarted)
  const animEnded = useAppSelector(selectAnimEnded)
  const { slug } = (useParams() as any) || {}

  const [srcData, setSrc] = useState<StateSrc>({
    src: null,
    bgColor: null,
    status: "opening",
  })

  const [animated, setAnimated] = useState<boolean>(false)

  const getBgColor = async (img: HTMLImageElement) => {
    const imageBitmap = await createImageBitmap(img)
    const pixel = await getPixelColor(imageBitmap, 3, 3)

    return rgbToHex(pixel[0], pixel[1], pixel[2])
  }

  useEffect(() => {
    if (slug) {
      const artW: Artwork = artworksData.find(
        (aw) => aw.slug === slug,
      ) as Artwork
      if (artW) {
        loadImage(artW.asset.large, (data: HTMLImageElement) => {
          getBgColor(data).then((bgColor: string) => {
            //setTimeout(() => setSrc({ src: data.src, bgColor }), toDuration)
            setSrc({
              src: data.src,
              bgColor,
              status: !srcData.src ? "opening" : "sliding",
            })
          })
        })
      }
    } else {
      //setTimeout(() => setSrc({ src: null, bgColor: null }), toDuration + 1000)
      setSrc({
        src: srcData.src,
        bgColor: srcData.bgColor,
        status: "closing",
      })
    }
  }, [slug, artworksData])

  useEffect(() => {
    if (animEnded && srcData.status === "closing") {
      setSrc({ src: null, bgColor: null, status: "opening" })
    }
  }, [animEnded])

  /**
   * quando si passa da null a slug > deve attendere che parta l'animazione prima di comparire
   * quando si passa da slug a slug > deve attendere che parta l'animazione prima di cambiare
   * quando si passa da slug a null > deve attendere che parta l'animazione prima di cambiare
   */
  const visible: boolean =
    ((srcData.status === "opening" || srcData.status === "sliding") &&
      (animStarted || animEnded)) ||
    (srcData.status === "closing" && !animStarted)

  return (
    <>
      {srcData.src && srcData.bgColor && visible && (
        <div id="artwork-container" className={style.root}>
          <CardBig src={srcData.src} bgColor={srcData.bgColor} />
        </div>
      )}
    </>
  )
}
