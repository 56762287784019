import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { fetchData } from "./artworksAPI"

export interface Artwork {
  title: string
  slug: string
  date: string
  asset: {
    thumb: string
    large: string
  }
}

export interface ArtworksState {
  status: "idle" | "loading" | "failed"
  data: Array<Artwork>
}

const initialState: ArtworksState = {
  status: "idle",
  data: [],
}

export const loadData = createAsyncThunk(
  "tees/fetchData",
  async (dataUrl: string) => {
    const response = await fetchData(dataUrl)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const artworksSlice = createSlice({
  name: "artworks",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadData.fulfilled, (state, action) => {
        state.status = "idle"
        state.data = action.payload
      })
      .addCase(loadData.rejected, (state) => {
        state.status = "failed"
      })
  },
})

//export const { } = teesSlice.actions

export const selectArtworks = (state: RootState) => state.artworks.data
export const selectArtworksStatus = (state: RootState) => state.artworks.status

export default artworksSlice.reducer
