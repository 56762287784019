import { useParams, useNavigate } from "react-router-dom"

import { useAppSelector } from "../../app/hooks"
import { selectArtworks } from "../artworks/artworksSlice"

import { Icon } from "../../components/icon/Icon"
import { BtnRound } from "../../components/btnRound/BtnRound"

import style from "./GalleryNav.module.scss"
import classNames from "classnames"
import { selectIsLoading } from "../loader/loaderSlice"

type Props = {
  onComplete?: Function
}

export function GalleryNav(props: Props) {
  //const itemRef = useRef<HTMLDivElement>(null)
  const artworksData = useAppSelector(selectArtworks)
  const loading = useAppSelector(selectIsLoading)
  const { slug } = (useParams() as any) || {}
  let navigate = useNavigate()

  const hasNext = (): boolean => {
    const idx = artworksData.findIndex((aw) => aw.slug === slug)
    return idx + 1 < artworksData.length
  }

  const gotoNext = () => {
    const idx = artworksData.findIndex((aw) => aw.slug === slug)
    if (idx + 1 < artworksData.length) {
      navigate("/artwork/" + artworksData[idx + 1].slug)
    }
  }

  const gotoPrev = () => {
    const idx = artworksData.findIndex((aw) => aw.slug === slug)
    if (idx - 1 >= 0) {
      navigate("/artwork/" + artworksData[idx - 1].slug)
    } else {
      navigate("/")
    }
  }

  if (loading) {
    return null
  }

  return (
    <>
      {slug && (
        <BtnRound
          className={classNames(style.btn, style.arrow)}
          onClick={gotoPrev}
        >
          <Icon type="chevron-left" />
        </BtnRound>
      )}
      {slug && hasNext() && (
        <BtnRound
          className={classNames(style.btn, style.next, style.arrow)}
          onClick={gotoNext}
        >
          <Icon type="chevron-right" />
        </BtnRound>
      )}
      {slug && (
        <BtnRound
          className={classNames(style.btn, style.close)}
          onClick={() => navigate("/")}
        >
          <Icon type="xmark" />
        </BtnRound>
      )}
    </>
  )
}
