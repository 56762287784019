import html2canvas from "html2canvas"

export async function getPixelColor(
  imageBitmap: ImageBitmap,
  x: number,
  y: number,
) {
  const offscreenCanvas = new OffscreenCanvas(1, 1)
  const context = offscreenCanvas.getContext("2d")
  context!.drawImage(imageBitmap, -x, -y)

  const pixelData = context!.getImageData(0, 0, 1, 1).data
  return [pixelData[0], pixelData[1], pixelData[2]]
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export function componentToHex(c: number) {
  const hex = c.toString(16)
  return hex.length === 1 ? "0" + hex : hex
}

export function loadImage(
  src: string,
  onComplete?: Function,
  onStart?: Function,
) {
  const img = new Image()
  img.onload = async function () {
    if (typeof onComplete === "function") {
      //console.log("image loaded", img)
      onComplete(img)
    }
  }

  if (typeof onStart === "function") {
    onStart()
  }
  img.src = src

  return img
}

export function getViewportScreenshot(
  targetId: string = "tees-container",
): Promise<string> {
  // Ottieni il corpo del documento o qualsiasi elemento che si desidera catturare
  const elementToCapture = document.getElementById(targetId)
  // Ottieni l'URL dell'immagine dal canvas
  return new Promise((resolve) => {
    if (!elementToCapture) {
      resolve("")
    }
    // Utilizza html2canvas per catturare lo screenshot
    html2canvas(elementToCapture!, {
      y: window.scrollY,
      height: window.innerHeight,
    }).then((canvas) => {
      // Ottieni l'URL dell'immagine dal canvas
      resolve(canvas.toDataURL("image/jpg"))
    })
  })
}
