import { useState, useEffect, useRef, useCallback } from "react"
import { useParams, useLocation } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"

import { loadingEnd, loadingStart } from "../loader/loaderSlice"
import { getViewportScreenshot } from "../../utils/imageUtils"
import { animationEnd, animationStart } from "../gallery/gallerySlice"

import { GalleryAnim } from "../../components/galleryAnim/GalleryAnim"

import picTh from "./japu_profile_th.png"
import pic from "./japu_profile_lg.png"
import classNames from "classnames"

import style from "./Profile.module.scss"
import { Icon } from "../../components/icon/Icon"
//import { BtnRound } from "../../components/btnRound/BtnRound"

type StateSrc = {
  from: string
  to: string
}

export function Profile() {
  const dispatch = useAppDispatch()
  const { pathname } = (useLocation() as any) || {}
  const isArtwork = pathname.indexOf("artwork") !== -1

  const prevSrcDataPrev = useRef<string>("")

  const [status, setStatus] = useState<"closed" | "animating" | "opened">(
    "closed",
  )

  const [srcData, setSrcData] = useState<StateSrc>({
    from: "",
    to: "",
  })

  const startOpening = () => {
    dispatch(loadingStart())
    getViewportScreenshot(
      isArtwork ? "artwork-container" : "tees-container",
    ).then((screenData) => {
      dispatch(loadingEnd())
      //setScreenshot(screenData)
      setSrcData({ from: screenData, to: "" })
    })
  }

  const startClosing = () => {
    dispatch(loadingStart())
    getViewportScreenshot("profile-container").then((screenData) => {
      dispatch(loadingEnd())
      //setScreenshot(screenData)
      setSrcData({ from: screenData, to: prevSrcDataPrev.current })
    })
  }

  return (
    <div className={style.root} id="profile-container">
      {status === "closed" && (
        <button
          onClick={startOpening}
          className={style.startBtn}
          style={{ backgroundImage: "url(" + picTh + ")" }}
        ></button>
      )}
      {srcData.from !== "" && (
        <GalleryAnim
          from={<img style={{ width: "100%" }} src={srcData.from} alt="" />}
          to={
            srcData.to === "" ? (
              <div className={style.profileWrapper} />
            ) : (
              <img style={{ height: "100%" }} src={srcData.to} alt="" />
            )
          }
          onStart={() => dispatch(animationStart())}
          onComplete={() => {
            dispatch(animationEnd())
            //setScreenshot("")
            prevSrcDataPrev.current = srcData.from + ""
            setSrcData({ from: "", to: "" })
            if (status === "opened") {
              setStatus("closed")
            } else {
              setStatus("opened")
            }
          }}
        />
      )}
      {status === "opened" && (
        <div className={style.profileWrapper}>
          <div className={style.profileContainer}>
            <img className={style.profileImg} src={pic} alt="" />
            <p className={style.profileTxt}>
              <span className="h1">Hi! I'm Japu,</span>
              <br /> a guy who likes drawing.
              <br />
              I'm inspired by everything I like as video games, manga, anime and
              more!
              <br />
              <a
                className={style.socialLink}
                href="https://www.instagram.com/japu_jwings/"
                target="_blank"
              >
                <Icon theme="secondary" type="instagram" />
              </a>
              <a
                className={style.socialLink}
                href="https://x.com/japu_jwings"
                target="_blank"
              >
                <Icon theme="secondary" type="twitter" />
              </a>
            </p>
            <button
              className={classNames("btn btn-primary", style.btnBack)}
              onClick={startClosing}
            >
              wow, too much infos, let's go back
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
