import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface LoaderState {
  animatingItems: number
  loadingItems: number
}

const initialState: LoaderState = {
  animatingItems: 0,
  loadingItems: 0,
}

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    animatingStart: (state) => {
      state.animatingItems = state.animatingItems + 1
    },
    animatingEnd: (state) => {
      let result = state.animatingItems - 1
      if (result < 0) {
        result = 0
      }
      state.animatingItems = result
    },
    loadingStart: (state) => {
      state.loadingItems = state.loadingItems + 1
    },
    loadingEnd: (state) => {
      let result = state.loadingItems - 1
      if (result < 0) {
        result = 0
      }
      state.loadingItems = result
    },
  },
})

export const { animatingStart, animatingEnd, loadingStart, loadingEnd } =
  loaderSlice.actions

export const selectIsLoading = (state: RootState) =>
  state.loader.loadingItems > 0
export const selectIsAnimating = (state: RootState) =>
  state.loader.animatingItems > 0

export default loaderSlice.reducer
