import { Artwork } from "./artworksSlice"

export function fetchData(dataUrl: string) {
  return new Promise<{ data: Array<Artwork> }>((resolve) => {
    //setTimeout(() => resolve({ data: [] }), 500),
    fetch(dataUrl).then((response) => {
      response.json().then((responseData) => resolve({ data: responseData }))
    })
  })
}
