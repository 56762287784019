import { useState, useEffect, useRef, useCallback } from "react"
import classNames from "classnames"

import { LoadSpinner } from "../loadSpinner/LoadSpinner"

import { getPixelColor, rgbToHex } from "../../utils/imageUtils"

import style from "./Card.module.scss"

type Props = {
  src?: string
  onLoadEnd?: Function
  onLoadStart?: Function
}

export function Card(props: Props) {
  const [src, setSrc] = useState<string | null>(null)
  const [bgColor, setBgColor] = useState<string | null>(null)

  const rootRef = useRef<HTMLDivElement>(null)
  const toRef = useRef<NodeJS.Timeout | null>(null)

  const lazyLoad = useCallback(() => {
    if (toRef.current) {
      clearTimeout(toRef.current)
    }

    toRef.current = setTimeout(() => {
      var scrollTop = window.scrollY
      if (
        rootRef.current &&
        rootRef.current.getBoundingClientRect().top +
          document.documentElement.scrollTop <
          window.innerHeight + scrollTop
      ) {
        document.removeEventListener("scroll", lazyLoad)
        window.removeEventListener("resize", lazyLoad)
        window.removeEventListener("orientationChange", lazyLoad)

        const img = new Image()
        img.onload = async function () {
          const imageBitmap = await createImageBitmap(img)
          const pixel = await getPixelColor(imageBitmap, 3, 3)

          const hexColor = rgbToHex(pixel[0], pixel[1], pixel[2])
          //console.log("Colore del pixel:", hexColor)

          setSrc(img.src)
          setBgColor(hexColor)

          if (typeof props.onLoadEnd === "function") {
            props.onLoadEnd()
          }
        }

        if (props.src) {
          if (typeof props.onLoadStart === "function") {
            props.onLoadStart()
          }
          img.src = props.src
        }
      }
    }, 20)
  }, [props])

  const bindEvents = useCallback(() => {
    document.addEventListener("scroll", lazyLoad)
    window.addEventListener("resize", lazyLoad)
    window.addEventListener("orientationChange", lazyLoad)
  }, [lazyLoad])

  const unbindEvents = useCallback(() => {
    document.removeEventListener("scroll", lazyLoad)
    window.removeEventListener("resize", lazyLoad)
    window.removeEventListener("orientationChange", lazyLoad)
  }, [lazyLoad])

  useEffect(() => {
    setTimeout(() => {
      bindEvents()
      lazyLoad()
    }, 250)

    return () => {
      unbindEvents()
    }
  }, [bindEvents, lazyLoad, unbindEvents])

  return (
    <div
      ref={rootRef}
      className={classNames(
        style.main,
        "d-flex",
        "justify-content-center",
        "align-items-center",
        {
          [style.loading]: !src,
        },
      )}
      style={{ backgroundColor: bgColor ? bgColor : "rgba(0,0,0,0.06)" }}
    >
      {src && <img src={src} alt="" />}
      {/*src ? (
        <img src={src} alt="" />
      ) : (
        <div>
          <LoadSpinner />
        </div>
      )*/}
    </div>
  )
}
