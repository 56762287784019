import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/store"
//import App from "./App"
import Root from "./routes/root"

import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/artwork/:slug",
        element: <Root />,
      },
    ],
  },
  /*{
    path: "/artwork/:slug",
    element: <Root />,
  },*/
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      {/*<App />*/}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
)
