import classNames from "classnames"

//import "./LoadSpinner.scss"
import style from "./LoadSpinner.module.scss"

type Props = {
  className?: string
}

export function LoadSpinner(props: Props) {
  return (
    <div className={classNames(style.root, props.className)}>
      <div className={style.spinner}></div>
    </div>
  )
}
