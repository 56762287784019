import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
//import counterReducer from "../features/counter/counterSlice"
import artworksReducer from "../features/artworks/artworksSlice"
import galleryReducer from "../features/gallery/gallerySlice"
import loaderReducer from "../features/loader/loaderSlice"

export const store = configureStore({
  reducer: {
    //counter: counterReducer,
    loader: loaderReducer,
    artworks: artworksReducer,
    gallery: galleryReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
