import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface GalleryState {
  items: Array<string>
  itemSelected: string
  animStarted: boolean
  animEnded: boolean
}

const initialState: GalleryState = {
  items: [],
  itemSelected: "",
  animStarted: false,
  animEnded: false,
}

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addGalleryItem: (state, action: PayloadAction<string>) => {
      state.items.push(action.payload)
    },
    removeGalleryItem: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item !== action.payload)
    },
    setGalleryItemSelected: (state, action: PayloadAction<string>) => {
      state.itemSelected = action.payload
    },
    animationStart: (state) => {
      state.animStarted = true
      state.animEnded = false
    },
    animationEnd: (state) => {
      state.animStarted = false
      state.animEnded = true
    },
  },
})

export const {
  addGalleryItem,
  removeGalleryItem,
  animationEnd,
  animationStart,
} = gallerySlice.actions

export const selectGallery = (state: RootState) => state.gallery.items
export const selectGallerySelected = (state: RootState) =>
  state.gallery.itemSelected
export const selectAnimStarted = (state: RootState) => state.gallery.animStarted
export const selectAnimEnded = (state: RootState) => state.gallery.animEnded

export default gallerySlice.reducer
